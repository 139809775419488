.cdk-overlay-pane{

    max-width: calc(100% - 6px) !important;
    max-height: calc(100% - 20px) !important;
    margin-top: 85px;
    margin-left: 10px;
    width: 100%;

    @include respond-above(sm) {
        width: 500px;
    }

    &.fullscreen-dialog {
        width: 100%;
        height: 100%;
    }

    &.in-iframe {
        max-height: calc(100% - 20px) !important;
        margin-top: 0px;
    }
}

.cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    border-radius: 25px;
}

.mdc-dialog .mat-mdc-dialog-surface .mat-mdc-dialog-title {
    display: flex;
    padding: 25px;
}

.mdc-dialog .mat-mdc-dialog-surface .modal-title {
    margin: 0;
    flex: 1;
    color: #212121;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins";
    line-height: normal;
    height: 26px;
    align-self: center;
}

.mdc-dialog .mat-mdc-dialog-surface .modal-header-commands {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mdc-dialog .mat-mdc-dialog-surface .mat-mdc-dialog-content {
    max-height: calc(100vh - 301px);
    padding: 0 25px;
    font-size: 14px;
    padding-bottom: 0;
    margin: 0;
    line-height: normal;
    overflow-y: auto;
    letter-spacing: 0;

    height: 100%;

    @include respond-above(sm) {
        height: unset;
    }

    .modal-content-with-padding {
        padding: 0px 25px;
    }
}

.fullscreen-dialog .mat-mdc-dialog-surface .mat-mdc-dialog-content {
    height: 100%;
    max-height: calc(100vh - 235px);
}

.mat-mdc-dialog-surface .mat-mdc-dialog-actions {
    height: 60px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}
