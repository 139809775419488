// Convertitore rgb to hex
// https://www.rgbtohex.net/

$std-assets-path: "/assets/std/";

$colors: (

  // New UX
  accent:         #006FDD,
  error:          #EE192B,
  alternative:    #6FB82E,
  hover:          #EAF5FF,
  light:          #BFBFBF,
  disabled:       #e0e0e0,
  select_dark:    #004891,

  primary:    		#173e6d,
  font:           #212121,
  white:          #ffffff,
  danger:         #ff0707,
  header:         #173e6d,
  zoom:           #2a83ee,
  icon:           #2a83ee,
  icon_hover:     #3b9bff3d, //occhio che il colore in esadecimale con trasparenza non corrisponde al colore del quadrato

  // Form Control
  formcontrol_checkbox:             #0d75f2,
  formcontrol_highlight_section:    #3b9bff3d, // #d9f0f2,
  formcontrol_hover_border:        	#0d75f2,
  formcontrol_focus_border:         #173e6d,
  formcontrol_normal_border:        #ababab,
  formcontrol_normal_background:    rgba(255,255,255,.85),
  button:                           #173e6d,
  button_font:                      #ffffff,
  button_hover:                     #3b94ff,
  button_font_hover:                #ffffff,

  // formcontrol_label_hover_border:         #173e6d,
  formcontrol_label_focus_border:   #173e6d,

  // Grid
  grid_normal_border:               #d3d6da,
  grid_column_hover:                #3b9bff3d,
  grid_resize_color:                #4794f0,

  // Focus

) !default;

$font-family:   "Poppins" !default;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

// Font size
$font-size-small:   11px !default;
$font-size-regular: 16px !default;
$font-size-medium:  13px !default;
$font-size-large:   14px !default;

// Responsive font size
$font-size-small-xs: 15px;
$font-size-small-sm: 11px;
$font-size-small-md: 11px;
$font-size-small-lg: 11px;

$font-size-regular-xs: 16px;
$font-size-regular-sm: 12px;
$font-size-regular-md: 12px;
$font-size-regular-lg: 12px;

$font-size-medium-xs: 17px;
$font-size-medium-sm: 13px;
$font-size-medium-md: 13px;
$font-size-medium-lg: 13px;

$font-size-large-xs: 18px;
$font-size-large-sm: 14px;
$font-size-large-md: 14px;
$font-size-large-lg: 14px;
