$color-background-error: #fde9eb;
$color-background-alert: #fbefde;
$color-background-info: #cdeaee;
$color-background-success:#cdeed1;

div.ngxp__container {
    color: color(font);
    border: 0;
    border-radius: 10px;
    max-width: 500px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
    z-index: 10000;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-style: normal;
    text-transform: none;

    &.error {
        background-color: $color-background-error;
        > .ngxp__arrow {
            &:before {
                background-color: $color-background-error;
            }
        }
    }
    &.alert {
        background-color: $color-background-alert;
        > .ngxp__arrow {
            &:before {
                background-color: $color-background-alert;
            }
        }
    }
    &.info {
        background-color: $color-background-info;
        > .ngxp__arrow {
            &:before {
                background-color: $color-background-info;
            }
        }
    }
    &.success {
        background-color: $color-background-success;
        > .ngxp__arrow {
            &:before {
                background-color: $color-background-success;
            }
        }
    }

   
}


// // Tooltip
// bs-tooltip-container {
//     &.tooltip-content {
//         // Popup Styling
//         z-index: 10000;
//         border: 0px;
//         border-radius: 10px;
//         color: color(font);
//         @include responsive-font-size(regular);
//         font-family: $font-family !important;
//         font-weight: $font-weight-medium !important;
//         font-style: normal !important;
//         text-transform: none !important;
//         display: inline;
//         // line-height: 24px;
//         position: absolute;
//         padding-top: 5px;
//         padding-bottom: 5px;
//         padding-right: 12px;
//         padding-left: 12px;
//         .arrow {
//             bottom: 0;
//             position: absolute;
//             display: block;
//             width: .8rem;
//             height: .4rem;

//             &:before{
//                 top: 0;
//                 border-width: .4rem .4rem 0;
//                 border-top-color: #000;
//                 position: absolute;
//                 content: "";
//                 border-color: transparent;
//                 border-style: solid;
//             }
//         }
//     }

// } // end .-tip