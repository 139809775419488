// Imports
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

mat-toolbar {
    &.mat-toolbar.mat-toolbar-single-row {
        background-color: white;
        height: 50px;
        column-gap: 10px;
        border-bottom: 1px solid #d3d6da;
    }
}
