@import 'abstracts/variables';
@import 'abstracts/functions';

/** SPLIT.JS **/

.gutter {
    background-color: #e6e7e9;
    background-repeat: no-repeat;
    background-position: 50%;

    &:hover, &:active {
        border-color:  #{color(formcontrol_hover_border)};
    }

    &.gutter-horizontal {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
        cursor: ew-resize;
        margin-right: 1px;
        margin-left: 1px;
    }

    &.gutter-vertical {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
        cursor: ns-resize;
    }
}