// Imports
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.cdk-overlay-pane.dropdown {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;

    @include respond-above(sm) {
        width: unset;
    }
}

.dropdown .mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: unset;
    overflow: visible;
    width: 100%;

    @include respond-above(sm) {
        width: unset;
    }
}
