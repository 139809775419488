//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$font-sizes: (
  small: (
    xs: $font-size-small-xs,
    sm: $font-size-small-sm,
    md: $font-size-small-md,
    lg: $font-size-small-lg
  ),
  regular: (
    xs: $font-size-regular-xs,
    sm: $font-size-regular-sm,
    md: $font-size-regular-md,
    lg: $font-size-regular-lg
  ),
  medium: (
    xs: $font-size-medium-xs,
    sm: $font-size-medium-sm,
    md: $font-size-medium-md,
    lg: $font-size-medium-lg
  ),
  large:(
    xs: $font-size-large-xs,
    sm: $font-size-large-sm,
    md: $font-size-large-md,
    lg: $font-size-large-lg
  ),
);


// @include respond-above(sm) {}
@mixin responsive-font-size($font-size, $important: false) {

  // If the font-size exists in the map.
  @if map-has-key($font-sizes, $font-size) {

    // Get the font-size value.
    $font-size-obj: map-get($font-sizes, $font-size);

    @if $important == true {
      font-size: map-get($font-size-obj, xs) !important;
    } @else {
      font-size: map-get($font-size-obj, xs) !important;
    }

    @if map-get($font-size-obj, xs) != map-get($font-size-obj, sm) {
      @include respond-above(sm) {
        @if $important == true {
          font-size: map-get($font-size-obj, sm) !important;
        } @else {
          font-size: map-get($font-size-obj, sm) !important;
        }
      }
    }
    
    @if map-get($font-size-obj, sm) != map-get($font-size-obj, md) {
      @include respond-above(md) {
        @if $important == true {
          font-size:  map-get($font-size-obj, md) !important;
        } @else {
          font-size:  map-get($font-size-obj, md) !important;
        }
      }
    }

    @if map-get($font-size-obj, md) != map-get($font-size-obj, lg) {
      @include respond-above(lg) {
        @if $important == true {
          font-size:  map-get($font-size-obj, lg) !important;
        } @else {
          font-size:  map-get($font-size-obj, lg) !important;
        }
      }
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid font size: #{$font-size}.';
  }
}