@import "~vanilla-cookieconsent/dist/cookieconsent.css";

$font-family: "Poppins";
$color-accent: #006FDD;
$color-hover: #EAF5FF;
$white: #ffffff;

joyride-step {
  .joyride-step__holder {

    font-family: $font-family;

    .joyride-step__container {
      border-radius: 25px;
      min-width: 300px;

      .joyride-step__close {
        right: 15px;
        top: 14px;

        &:focus,
        &:hover {
          outline: none;
          background-color: #eaf5ff;
        }
      }

      .joyride-button {
        background-color: $color-accent  !important;
        display: flex;
        border: none;
        border-radius: 18px;
        height: 100%;
        cursor: pointer;
        padding: 8px 15px;

        &:focus,
        &:hover {
          outline: solid;
          outline-color: $color-hover  !important;
          outline-width: 5px;
          color: $white  !important;
        }
      }

      .joyride-step__title {
        margin: 0;
        line-height: 1.428571429;
        flex: 1;
        color: #212121 !important;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .joyride-step__body {
        text-transform: none;
        color: #212121 !important;
        font-size: 13px;
        font-weight: 500;
        margin: 0;
        text-align: left;
        padding: 10px 8px;
      }
    }
  }
}
